import React from 'react';
import { useSelector } from '../../../common/components/runtime-context';
import { getArchiveWidgetData } from '../../aggregated-archive/selectors';
import LinkList from '../link-list-new';
import LinkText from '../link-text';

const Archive = () => {
  const { archive, emptyState, ariaLabel } = useSelector(getArchiveWidgetData);

  const links = archive.map((archiveLink) => ({
    key: archiveLink.key,
    path: archiveLink.path,
    text: (
      <LinkText
        postCount={archiveLink.display.postCount}
        a11yText={archiveLink.display.a11yText}
      >
        {archiveLink.display.text}
      </LinkText>
    ),
  }));

  return (
    <nav aria-label={ariaLabel}>
      <LinkList links={links} emptyState={{ text: emptyState }} />
    </nav>
  );
};

export default Archive;
