import React from 'react';
import { flowRight } from 'lodash';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import Archive from '../archive';
import ArchiveNew from '../archive-new';
import styles from './app-root.scss';

const AppRoot = () => {
  const { experiments } = useExperiments();
  return (
    <ResponsiveListener
      dataHook="archive-widget-root"
      className={styles.baseStyles}
    >
      {experiments.enabled(
        OOI_EXPERIMENTS.USE_NEW_ARCHIVE_WIDGET_SETTINGS_URL,
      ) ? (
        <ArchiveNew />
      ) : (
        <Archive />
      )}
      <AppLoaded />
    </ResponsiveListener>
  );
};

const mapRuntimeToProps = (state) => {
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    sectionUrl: getSectionUrl(state),
  };
};

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
